import React from "react";
import PropTypes from "prop-types";

const Card = props => {
  const { 
    children, 
    containerStyle,
    style,
  } = props;
  return (
    <div className="card-container" style={containerStyle}>
      <div className="card" {...{ style }}>
        { children ? children : null }
      </div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.any,
  containerStyle: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  style: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
};

export default Card;
