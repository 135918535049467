import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addArticles } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import Card from "../../hocs/Card.hoc";
import DefaultButton from "../../common/button/Button.common";

const AddArticlesPage = (props) => {
  const { article, addArticles } = props;
  const { addArticleLoader } = article;
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();
  const [image, setImage] = useState();
  const [file, setFile] = useState();

  const onSubmit = (data) => {
    let formData = new FormData();

    if (image) {
      formData.append("image", image);
    }
    formData.append("title", data.title);
    if (data.article_link) {
      formData.append("articleLink", data.article_link);
    }
    if (data.content) {
      formData.append("content", data.content);
    }

    if (file && file[0]) {
      formData.append("pdf", file[0]);
    }
    addArticles(formData, handleRedirection, toast);
  };

  const handleRedirection = () => {
    navigate(routes.Articles.path);
  };

  const onChangeImage = (imageList) => {
    setImage(imageList[0]?.file);
  };

  const onChangeFile = (event) => {
    setFile([event.target.files[0]]);
  };

  return (
    <CompWrapper>
      <Header title="Add Articles" />
      <div className="addposts-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{ cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate(routes.Articles.path)}>
            Articles
          </Link>
          <Typography color="textPrimary">Add Articles</Typography>
        </Breadcrumbs>
        <div className="addarticles">
          <Card>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: 10,
              }}>
              <TextField
                name="title"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Title*"
              />
              <TextField
                name="article_link"
                inputRef={register()}
                variant="outlined"
                label="Article Link"
              />
              <TextField
                name="content"
                multiline
                rows={4}
                inputRef={register()}
                variant="outlined"
                label="Content"
              />

              <ImageUploading multiple={false} onChange={onChangeImage}>
                {({ imageList, onImageUpload }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat( auto-fill, minmax(200px, 1fr) )",
                        columnGap: 10,
                      }}>
                      {imageList.map((image) => (
                        <div key={image.key}>
                          <img
                            src={image.dataURL}
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      className="button-sec"
                      color="secondary"
                      onClick={onImageUpload}
                      variant="contained">
                      Upload Image
                    </Button>
                  </div>
                )}
              </ImageUploading>

              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  overflow: "hidden",
                  padding: 10,
                  paddingLeft: 0,
                }}>
                <input
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    opacity: 0,
                    fontSize: 50,
                  }}
                  accept="application/pdf"
                  type="file"
                  multiple
                  name="thumbnail"
                  onChange={(event) => {
                    onChangeFile(event);
                  }}
                />
                <DefaultButton onClick={() => false} title="Add Pdf File" />
              </div>
              {file && <div className="text-rumpel">{file[0]?.name}</div>}
              <ActivityIndicator animating={addArticleLoader}>
                <Button
                  className="button-pri"
                  variant="contained"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </Card>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.article,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addArticles,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddArticlesPage);
