import React from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginAction } from "../../actions/Actions";

//IMPORT CARDS
import Card from "../hocs/Card.hoc";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

//IMPORT IMAGES
import illustrator from "../../assets/book-svg.svg";
//IMPORT LOGO
import LOGO from "../../assets/logo.jpg";

const LoginPage = (props) => {
  const { navigation } = useNavigation();
  const { handleLogin, toast } = useAuth();
  const { routes, navigate } = navigation;
  const { classes, loginAction, login } = props;
  const { loginLoader } = login;
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => {
    const { email, password } = data;
    let body = {
      email: email,
      password: password,
    };
    loginAction(body, handleLogin, toast);
  };

  return (
    <div className="loginpage-container">
      <div className="loginpage">
        <div className="logo">
          <img src={LOGO} alt="Book App" />
        </div>
        <div className="loginpage-desktop">
          <div className="illustrator">
            <img alt="Illustration" src={illustrator} />
          </div>
          <div className="loginform">
            <Card
              containerStyle={{
                width: 400,
                paddingTop: 30,
                paddingBottom: 30,
              }}>
              <div className="loginform-card">
                <div className="loginform-card-title">Author Login</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="loginform-card-form">
                    <TextField
                      style={{ marginTop: 20 }}
                      label="Email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      inputRef={register({ required: true })}
                    />
                    <TextField
                      style={{ marginTop: 20 }}
                      label="Password"
                      name="password"
                      fullWidth
                      type="password"
                      variant="outlined"
                      inputRef={register({ required: true })}
                    />
                    <ActivityIndicator
                      animating={loginLoader}
                      style={{
                        paddingBottom: 0,
                      }}>
                      <Button className="button" fullWidth type="submit">
                        Login
                      </Button>
                    </ActivityIndicator>
                  </div>
                </form>
                {/* <div className="loginform-card-register">
                  Not registered?&nbsp;
                  <span onClick={() => navigate(routes["Signup"].path)}>
                    Register here
                  </span>
                </div> */}
              </div>
            </Card>
          </div>
        </div>
        <div className="loginpage-mobile" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
