import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editPosts, getPostDetailAction } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import Card from "../../hocs/Card.hoc";

const EditPostPage = (props) => {
  const { post, editPosts, getPostDetailAction } = props;
  const { editPostLoader, getPostDetailLoader, postDetail } = post;
  const { params, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    getPostDetailAction(params.id);
  }, [getPostDetailAction]);

  const onSubmit = (data) => {
    let formData = new FormData();
    if (image) {
      formData.append("image", image);
    }
    formData.append("title", data.title);
    formData.append("content", data.content);

    editPosts(params.id, formData, handleRedirection, toast);
  };

  const handleRedirection = () => {
    navigate(routes.Posts.path);
  };

  const onChangeImage = (imageList) => {
    setImage(imageList[0]?.file);
  };

  return (
    <CompWrapper>
      <Header title="Edit Posts" />
      {postDetail ? (
        <div className="addposts-container">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{ cursor: "pointer" }}
              color="inherit"
              onClick={() => navigate(routes.Posts.path)}>
              Posts
            </Link>
            <Typography color="textPrimary">Edit Posts</Typography>
          </Breadcrumbs>
          <div className="addposts">
            <Card>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  rowGap: 10,
                }}>
                <TextField
                  name="title"
                  inputRef={register({ required: true })}
                  variant="outlined"
                  label="Title*"
                  defaultValue={postDetail?.title}
                />
                <TextField
                  name="content"
                  multiline
                  rows={4}
                  inputRef={register({ required: true })}
                  variant="outlined"
                  label="Content*"
                  defaultValue={postDetail?.content}
                />

                <ImageUploading multiple={false} onChange={onChangeImage}>
                  {({ imageList, onImageUpload }) => (
                    <div className="upload__image-wrapper">
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat( auto-fill, minmax(200px, 1fr) )",
                          columnGap: 10,
                        }}>
                        {imageList.map((image) => (
                          <div key={image.key}>
                            <img
                              src={image.dataURL}
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <Button
                        className="button-sec"
                        color="secondary"
                        onClick={onImageUpload}
                        variant="contained">
                        Change Image
                      </Button>
                    </div>
                  )}
                </ImageUploading>

                {imageError && (
                  <div style={{ color: "red" }}>
                    <strong>Image is Required!!</strong>
                  </div>
                )}
                <ActivityIndicator animating={editPostLoader}>
                  <Button
                    className="button-pri"
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                </ActivityIndicator>
              </form>
            </Card>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    post: state.post,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editPosts,
      getPostDetailAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPostPage);
