import LoginPage from "../loginPage/LoginPage";
import SignUpPage from "../signupPage/SignupPage";
import NotFoundPage from "../notFoundPage/NotFoundPage";
import BookPage from "../bookPage/BookPage";
import AddBookPage from "../bookPage/addBookPage/AddBookPage";
import EditBookPage from "../bookPage/editBookPage/EditBookPage";
import PostPage from "../postPage/PostPage";
import AddPostPage from "../postPage/addPostPage/AddPostPage";
import EditPostPage from "../postPage/editPostPage/EditPostPage";
import ArticlePage from "../articlePage/ArticlePage";
import AddArticlePage from "../articlePage/addArticlePage/AddArticlePage";
import EditArticlePage from "../articlePage/editArticlePage/EditArticlePage";
import VideoPage from "../videoPage/VideoPage";
import AddVideoPage from "../videoPage/addVideoPage/AddVideoPage";
import EditVideoPage from "../videoPage/editVideoPage/EditVideoPage";
import PrivacyPolicy from "../privacyPage/PrivacyPage";

export const PUBLICPATHS = [
  {
    key: "Home",
    name: "Home",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    key: "Login",
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    restricted: true,
  },
  {
    key: "Privacy",
    name: "Privacy",
    path: "/privacy-policy",
    component: PrivacyPolicy,
    restricted: true,
  },
  // {
  //   key: "Signup",
  //   name: "Signup",
  //   path: "/sign-up",
  //   component: SignUpPage,
  //   restricted: true,
  // },
  {
    path: null,
    component: NotFoundPage,
  },
];

export const PRIVATEPATHS = [
  {
    key: "Books",
    name: "Books",
    path: "/books",
    component: BookPage,
  },
  {
    key: "AddBooks",
    name: "AddBooks",
    path: "/books/add",
    component: AddBookPage,
  },
  {
    key: "EditBooks",
    name: "EditBooks",
    path: "/books/edit/:id",
    component: EditBookPage,
  },
  {
    key: "Posts",
    name: "Posts",
    path: "/posts",
    component: PostPage,
  },
  {
    key: "AddPosts",
    name: "AddPosts",
    path: "/posts/add",
    component: AddPostPage,
  },
  {
    key: "EditPosts",
    name: "EditPosts",
    path: "/posts/edit/:id",
    component: EditPostPage,
  },
  {
    key: "Articles",
    name: "Articles",
    path: "/articles",
    component: ArticlePage,
  },
  {
    key: "AddArticles",
    name: "AddArticles",
    path: "/articles/add",
    component: AddArticlePage,
  },
  {
    key: "EditArticles",
    name: "EditArticles",
    path: "/articles/edit/:id",
    component: EditArticlePage,
  },
  {
    key: "Videos",
    name: "Videos",
    path: "/videos",
    component: VideoPage,
  },
  {
    key: "AddVideos",
    name: "AddVideos",
    path: "/videos/add",
    component: AddVideoPage,
  },
  {
    key: "EditVideos",
    name: "EditVideos",
    path: "/videos/edit/:id",
    component: EditVideoPage,
  },
];
