import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editVideos, getVideoDetailAction } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import Card from "../../hocs/Card.hoc";

const EditVideoPage = (props) => {
  const { video, editVideos, getVideoDetailAction } = props;
  const { editVideoLoader, getVideoDetailLoader, videoDetail } = video;
  const { params, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();

  useEffect(() => {
    getVideoDetailAction(params.id);
  }, [getVideoDetailAction]);

  const onSubmit = (data) => {
    if (data.content) {
      let body = {
        type: data.video_type,
        title: data.title,
        content: data.content,
        videoLink:
          data.video_link &&
          data.video_link.match(
            /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/,
          )[1],
      };
      editVideos(params.id, body, handleRedirection, toast);
    } else {
      let body = {
        type: data.video_type,
        title: data.title,
        videoLink:
          data.video_link &&
          data.video_link.match(
            /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/,
          )[1],
      };
      editVideos(params.id, body, handleRedirection, toast);
    }
  };

  const handleRedirection = () => {
    navigate(routes.Videos.path);
  };

  return (
    <CompWrapper>
      <Header title="Edit Videos" />
      <div className="addvideos-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{ cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate(routes.Videos.path)}>
            Articles
          </Link>
          <Typography color="textPrimary">Edit Videos</Typography>
        </Breadcrumbs>
        <div className="addarticles">
          {videoDetail && !getVideoDetailLoader ? (
            <Card>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  rowGap: 10,
                }}>
                <FormControl variant="outlined" control={control}>
                  <InputLabel id="leauge">Video Type</InputLabel>

                  <Controller
                    as={
                      <Select label="Video Type">
                        <MenuItem value="">
                          <em>Select Video Type</em>
                        </MenuItem>
                        <MenuItem value="lecture">Lecture</MenuItem>
                        <MenuItem value="interview">Interview</MenuItem>
                      </Select>
                    }
                    name="video_type"
                    rules={{ required: "This is required" }}
                    control={control}
                    defaultValue={videoDetail.type}
                  />
                </FormControl>
                <TextField
                  name="title"
                  inputRef={register({ required: true })}
                  variant="outlined"
                  label="Title*"
                  defaultValue={videoDetail.title}
                />
                <TextField
                  name="content"
                  multiline
                  rows={4}
                  inputRef={register()}
                  variant="outlined"
                  label="Content"
                  defaultValue={videoDetail.content}
                />
                <TextField
                  name="video_link"
                  inputRef={register({ required: true })}
                  variant="outlined"
                  label="Video Link*"
                  defaultValue={`https://www.youtube.com/watch?v=${videoDetail.video_link}`}
                />
                <ActivityIndicator animating={editVideoLoader}>
                  <Button
                    className="button-pri"
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                </ActivityIndicator>
              </form>
            </Card>
          ) : (
            <div>loading...</div>
          )}
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    video: state.video,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editVideos,
      getVideoDetailAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVideoPage);
