import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addVideos } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import Card from "../../hocs/Card.hoc";

const AddVideoPage = (props) => {
  const { video, addVideos } = props;
  const { addVideoLoader } = video;
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();

  const onSubmit = (data) => {
    if (data.content) {
      let body = {
        type: data.video_type,
        title: data.title,
        content: data.content,
        videoLink:
          data.video_link &&
          data.video_link.match(
            /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/,
          )[1],
      };
      addVideos(body, handleRedirection, toast);
    } else {
      let body = {
        type: data.video_type,
        title: data.title,
        videoLink:
          data.video_link &&
          data.video_link.match(
            /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/,
          )[1],
      };
      addVideos(body, handleRedirection, toast);
    }
  };

  const handleRedirection = () => {
    navigate(routes.Videos.path);
  };

  return (
    <CompWrapper>
      <Header title="Add Videos" />
      <div className="addvideos-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{ cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate(routes.Videos.path)}>
            Articles
          </Link>
          <Typography color="textPrimary">Add Videos</Typography>
        </Breadcrumbs>
        <div className="addarticles">
          <Card>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: 10,
              }}>
              <FormControl variant="outlined" control={control}>
                <InputLabel id="leauge">Video Type</InputLabel>

                <Controller
                  as={
                    <Select label="Video Type">
                      <MenuItem value="">
                        <em>Select Video Type</em>
                      </MenuItem>
                      <MenuItem value="lecture">Lecture</MenuItem>
                      <MenuItem value="interview">Interview</MenuItem>
                    </Select>
                  }
                  name="video_type"
                  rules={{ required: "This is required" }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
              <TextField
                name="title"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Title*"
              />
              <TextField
                name="content"
                multiline
                rows={4}
                inputRef={register()}
                variant="outlined"
                label="Content"
              />
              <TextField
                name="video_link"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Video Link*"
              />
              <ActivityIndicator animating={addVideoLoader}>
                <Button
                  className="button-pri"
                  variant="contained"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </Card>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    video: state.video,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addVideos,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoPage);
