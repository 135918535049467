import {
  GET_ARTICLE,
  ADD_ARTICLE,
  GET_ARTICLE_DETAIL,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
} from "../actions/Actions";

const initalState = {
  articleLoader: false,
  articleList: [],
  addArticleLoader: false,
  getArticleDetailLoader: false,
  articleDetail: null,
  editArticleLoader: false,
  deleteArticleLoader: false,
};

export function articleReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ARTICLE.LOADING:
      return { ...state, articleLoader: true, error: false };
    case GET_ARTICLE.SUCCESS:
      return {
        ...state,
        articleLoader: false,
        articleList: payload,
        error: false,
      };
    case GET_ARTICLE.ERROR:
      return { ...state, articleLoader: false, articleList: [], error: true };

    case ADD_ARTICLE.LOADING:
      return { ...state, addArticleLoader: true, error: false };
    case ADD_ARTICLE.SUCCESS:
      return {
        ...state,
        addArticleLoader: false,
        error: false,
      };
    case ADD_ARTICLE.ERROR:
      return { ...state, addArticleLoader: false, error: true };

    case GET_ARTICLE_DETAIL.LOADING:
      return { ...state, getArticleDetailLoader: true };
    case GET_ARTICLE_DETAIL.SUCCESS:
      return {
        ...state,
        getArticleDetailLoader: false,
        articleDetail: payload,
      };
    case GET_ARTICLE_DETAIL.ERROR:
      return { ...state, getArticleDetailLoader: false };

    case EDIT_ARTICLE.LOADING:
      return { ...state, editArticleLoader: true, error: false };
    case EDIT_ARTICLE.SUCCESS:
      return {
        ...state,
        editArticleLoader: false,
        error: false,
      };
    case EDIT_ARTICLE.ERROR:
      return { ...state, editArticleLoader: false, error: true };

    case DELETE_ARTICLE.LOADING:
      return { ...state, deleteArticleLoader: true, error: false };
    case DELETE_ARTICLE.SUCCESS:
      return {
        ...state,
        deleteArticleLoader: false,
        error: false,
      };
    case DELETE_ARTICLE.ERROR:
      return { ...state, deleteArticleLoader: false, error: true };
    default:
      return state;
  }
}
