import {
  GET_ARTICLE,
  ADD_ARTICLE,
  GET_ARTICLE_DETAIL,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
} from "./Actions";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";

export function getArticles() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ARTICLE.LOADING });
      res = await api(APIS.getArticle);

      const { success, data } = res;

      if (success === true || success === "true") {
        dispatch({ type: GET_ARTICLE.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_ARTICLE.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ARTICLE.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addArticles(formdata, handleRedirection, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_ARTICLE.LOADING });
      res = await api(`${APIS.articles}`, "POST", formdata, "formdata");

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: ADD_ARTICLE.SUCCESS });
        toast({ message: "Successfully Added Article", type: "success" });
        handleRedirection();
      } else {
        dispatch({ type: ADD_ARTICLE.ERROR });
        toast({ message: "Error Adding Article", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_ARTICLE.ERROR });
      toast({ message: "Error Adding Article", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function getArticleDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ARTICLE_DETAIL.LOADING });
      res = await api(`${APIS.articles}/${id}`);

      const { success, data } = res;
      console.log(res);
      if (success) {
        dispatch({ type: GET_ARTICLE_DETAIL.SUCCESS, payload: data.data[0] });
      } else {
        dispatch({ type: GET_ARTICLE_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ARTICLE_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editArticles(id, formdata, handleRedirection, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_ARTICLE.LOADING });
      res = await api(`${APIS.articles}/${id}`, "PATCH", formdata, "formdata");

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: EDIT_ARTICLE.SUCCESS });
        toast({ message: "Successfully Edited Article", type: "success" });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_ARTICLE.ERROR });
        toast({ message: "Error Editing Article", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_ARTICLE.ERROR });
      toast({ message: "Error Editing Article", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function deleteArticleAction(id, closeModal, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_ARTICLE.LOADING });
      res = await api(`${APIS.articles}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast({ message: "Successfully Deleted Article", type: "success" });
        dispatch({ type: DELETE_ARTICLE.SUCCESS });
        closeModal();
        dispatch(getArticles());
      } else {
        dispatch({ type: DELETE_ARTICLE.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_ARTICLE.ERROR });
      console.error(message);
      return 0;
    }
  };
}
