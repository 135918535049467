import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addBooks } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import DefaultButton from "../../common/button/Button.common";
import Card from "../../hocs/Card.hoc";

const AddBookPage = (props) => {
  const { book, addBooks } = props;
  const { addBookLoader } = book;
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control, errors } = useForm();
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState();
  const [showFileError, setShowFileError] = useState(false);
  const [chapterNumber, setChapterNumber] = useState(1);

  const onSubmit = (data) => {
    let content = [];
    for (let index = 0; index < data.chapter.length; index++) {
      content.push({
        name: data.chapter[index],
        start: data.start[index],
      });
    }

    let formData = new FormData();

    formData.append("image", image);
    if (file === undefined || file[0] === undefined) {
      setShowFileError(true);
    } else {
      formData.append("pdf", file[0]);
    }
    formData.append("bookName", data.book_name);
    formData.append("genre", data.genre);
    formData.append("year", data.year);
    formData.append("description", data.description);
    formData.append("content", JSON.stringify(content));

    if (image) {
      if (!showFileError) {
        addBooks(formData, handleRedirection, toast);
      }
    } else {
      setImageError(true);
    }
  };

  const handleRedirection = () => {
    navigate(routes.Books.path);
  };

  const onChangeImage = (imageList) => {
    imageList[0]?.file && setImageError(false);
    setImage(imageList[0]?.file);
  };

  const onChangeFile = (event) => {
    setFile([event.target.files[0]]);
    setShowFileError(false);
  };

  console.log("Haha", book);

  return (
    <CompWrapper>
      <Header title="Add Books" />
      <div className="addbooks-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{ cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate(routes.Books.path)}>
            Books
          </Link>
          <Typography color="textPrimary">Add Books</Typography>
        </Breadcrumbs>
        <div className="addbooks">
          <Card>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: 10,
              }}>
              <TextField
                name="book_name"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Book Name*"
              />
              <div className="input-container">
                <TextField
                  name="year"
                  inputRef={register({ required: true })}
                  variant="outlined"
                  label="Year*"
                  type="number"
                />
                <FormControl
                  variant="outlined"
                  control={control}
                  error={Boolean(errors.genre)}>
                  <InputLabel id="genre">Language</InputLabel>

                  <Controller
                    as={
                      <Select label="Genre">
                        <MenuItem value="">
                          <em>Select Language</em>
                        </MenuItem>
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Arabic">Arabic</MenuItem>
                        <MenuItem value="Turkish">Turkish</MenuItem>
                        <MenuItem value="Malay-Indonesian">
                          Malay-Indonesian
                        </MenuItem>
                        <MenuItem value="French">French</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    }
                    name="genre"
                    rules={{ required: "This is required" }}
                    control={control}
                    defaultValue=""
                  />
                </FormControl>
              </div>

              <TextField
                name="description"
                multiline
                rows={4}
                inputRef={register({ required: true })}
                variant="outlined"
                label="Description*"
                inputProps={{ maxLength: 250 }}
              />

              <div className="button-container">
                <Button
                  onClick={() => setChapterNumber(chapterNumber + 1)}
                  className="button-pri"
                  variant="contained">
                  Add Chapter
                </Button>
                {chapterNumber > 1 && (
                  <Button
                    onClick={() => setChapterNumber(chapterNumber - 1)}
                    className="button-sec"
                    color="secondary"
                    variant="contained">
                    Remove
                  </Button>
                )}
              </div>
              {Array(chapterNumber)
                .fill(10)
                .map((_, index) => {
                  return (
                    <div key={index} className="input-container three">
                      <TextField
                        name={`chapter[${index}]`}
                        inputRef={register({ required: true })}
                        variant="outlined"
                        label="Chapter Name*"
                      />
                      <TextField
                        name={`start[${index}]`}
                        inputRef={register({ required: true })}
                        variant="outlined"
                        label="Start Page*"
                        type="number"
                      />
                    </div>
                  );
                })}

              <ImageUploading multiple={false} onChange={onChangeImage}>
                {({ imageList, onImageUpload }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{
                        marginTop: 20,
                        display: "grid",
                        gridTemplateColumns:
                          "repeat( auto-fill, minmax(200px, 1fr) )",
                        columnGap: 10,
                      }}>
                      {imageList.map((image) => (
                        <div key={image.key}>
                          <img
                            src={image.dataURL}
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      className="button-sec"
                      color="secondary"
                      onClick={onImageUpload}
                      variant="contained">
                      Upload Image
                    </Button>
                  </div>
                )}
              </ImageUploading>

              {imageError && (
                <div style={{ color: "red" }}>
                  <strong>Image is Required!!</strong>
                </div>
              )}

              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  overflow: "hidden",
                  padding: 10,
                  paddingLeft: 0,
                }}>
                <input
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    opacity: 0,
                    fontSize: 50,
                  }}
                  accept="application/pdf"
                  type="file"
                  multiple
                  name="thumbnail"
                  onChange={(event) => {
                    onChangeFile(event);
                  }}
                />
                <DefaultButton onClick={() => false} title="Add Pdf File" />
              </div>
              {file && <div className="text-rumpel">{file[0]?.name}</div>}
              {showFileError && (
                <div style={{ color: "red" }}>
                  <strong>File is Required!!</strong>
                </div>
              )}
              <ActivityIndicator animating={addBookLoader}>
                <Button
                  className="button-pri"
                  variant="contained"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </Card>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    book: state.book,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addBooks,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBookPage);
