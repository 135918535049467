import {
  GET_POST,
  ADD_POST,
  GET_POST_DETAIL,
  EDIT_POST,
  DELETE_POST,
} from "../actions/Actions";

const initalState = {
  postLoader: false,
  postList: [],
  addPostLoader: false,
  getPostDetailLoader: false,
  postDetail: null,
  editPostLoader: false,
  deletePostLoader: false,
};

export function postReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POST.LOADING:
      return { ...state, postLoader: true, error: false };
    case GET_POST.SUCCESS:
      return {
        ...state,
        postLoader: false,
        postList: payload,
        error: false,
      };
    case GET_POST.ERROR:
      return { ...state, postLoader: false, postList: [], error: true };

    case ADD_POST.LOADING:
      return { ...state, addPostLoader: true, error: false };
    case ADD_POST.SUCCESS:
      return {
        ...state,
        addPostLoader: false,
        error: false,
      };
    case ADD_POST.ERROR:
      return { ...state, addPostLoader: false, error: true };

    case GET_POST_DETAIL.LOADING:
      return { ...state, getPostDetailLoader: true, postDetail: null };
    case GET_POST_DETAIL.SUCCESS:
      return {
        ...state,
        getPostDetailLoader: false,
        postDetail: payload,
      };
    case GET_POST_DETAIL.ERROR:
      return { ...state, getPostDetailLoader: false };

    case EDIT_POST.LOADING:
      return { ...state, editPostLoader: true, error: false };
    case EDIT_POST.SUCCESS:
      return {
        ...state,
        editPostLoader: false,
        error: false,
      };
    case EDIT_POST.ERROR:
      return { ...state, editPostLoader: false, error: true };

    case DELETE_POST.LOADING:
      return { ...state, deletePostLoader: true, error: false };
    case DELETE_POST.SUCCESS:
      return {
        ...state,
        deletePostLoader: false,
        error: false,
      };
    case DELETE_POST.ERROR:
      return { ...state, deletePostLoader: false, error: true };

    default:
      return state;
  }
}
