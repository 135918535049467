import React, { useState } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { MdAddAPhoto, IoIosCloseCircle } from "react-icons/all";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signupAction } from "../../actions/Actions";

//IMPORT CARDS
import Card from "../hocs/Card.hoc";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

//IMPORT IMAGES
import illustrator from "../../assets/book-svg.svg";
//IMPORT LOGO
import LOGO from "../../assets/logo.jpg";

const SignupPage = (props) => {
  const { navigation } = useNavigation();
  const { handleLogin, toast } = useAuth();
  const { routes, navigate } = navigation;
  const { classes, signupAction, login } = props;
  const { signupLoader } = login;
  const { register, handleSubmit, watch, errors } = useForm();

  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);

  const onSubmit = (data) => {
    let formData = new FormData();

    formData.append("image", image);
    formData.append("authorName", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);

    if (image) {
      signupAction(formData, handleRedirection, toast);
    } else {
      setImageError(true);
    }
  };

  const handleRedirection = () => {
    navigate(routes["Login"].path);
  };

  const onChangeImage = (imageList) => {
    imageList[0]?.file && setImageError(false);
    setImage(imageList[0]?.file);
  };

  return (
    <div className="loginpage-container">
      <div className="loginpage">
        <div className="logo">
          <img src={LOGO} alt="Book App" />
        </div>
        <div className="loginpage-desktop">
          <div className="illustrator">
            <img alt="Illustration" src={illustrator} />
          </div>
          <div className="loginform">
            <Card
              containerStyle={{
                width: 400,
                paddingTop: 30,
                paddingBottom: 30,
              }}>
              <div className="loginform-card">
                <div className="loginform-card-title">Author Signup</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="loginform-card-form">
                    <TextField
                      style={{ marginTop: 20 }}
                      label="Name"
                      name="name"
                      variant="outlined"
                      fullWidth
                      inputRef={register({ required: true })}
                    />
                    <TextField
                      style={{ marginTop: 20 }}
                      label="Email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      inputRef={register({ required: true })}
                    />
                    <TextField
                      style={{ marginTop: 20 }}
                      label="Password"
                      name="password"
                      fullWidth
                      type="password"
                      variant="outlined"
                      inputRef={register({ required: true })}
                    />
                    <ImageUploading multiple={false} onChange={onChangeImage}>
                      {({ imageList, onImageUpload }) => (
                        <div className="upload__image-wrapper">
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns:
                                "repeat( auto-fill, minmax(40px, 1fr) )",
                              columnGap: 10,
                            }}>
                            {imageList.map((image) => (
                              <div key={image.key}>
                                <img
                                  src={image.dataURL}
                                  alt=""
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 20,
                                    objectFit: "cover",
                                    overflow: "hidden",
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                          <Button
                            className="button-sec"
                            color="secondary"
                            onClick={onImageUpload}
                            variant="contained">
                            <MdAddAPhoto />
                          </Button>
                        </div>
                      )}
                    </ImageUploading>

                    {imageError && (
                      <div style={{ color: "red" }}>
                        <strong>Image is Required!!</strong>
                      </div>
                    )}
                    <ActivityIndicator
                      animating={signupLoader}
                      style={{
                        paddingBottom: 0,
                      }}>
                      <Button className="button" fullWidth type="submit">
                        Signup
                      </Button>
                    </ActivityIndicator>
                  </div>
                </form>

                <div className="loginform-card-register">
                  Already registered?&nbsp;
                  <span onClick={() => navigate(routes["Login"].path)}>
                    Login here
                  </span>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="loginpage-mobile" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signupAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
