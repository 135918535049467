import React, { useState, useEffect } from "react";
import { useNavigation, Modal, useAuth } from "react-uicomp";
import MaterialTable from "material-table";
import { Button, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVideos, deleteVideoAction } from "../../actions/Actions";
import { BASE_URL_MEDIA } from "../../config/Config";

import CompWrapper from "../hocs/CompWrapper.hoc";
import Header from "../common/header/Header.common";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

const VideoPage = (props) => {
	const { navigation } = useNavigation();
	const { toast } = useAuth();
	const { navigate, routes } = navigation;
	const { video, getVideos, deleteVideoAction } = props;
	const { videoList, videoLoader, deleteVideoLoader } = video;
	const [visible, setVisible] = useState(false);
	const [active, setActive] = useState();

	const deleteModal = (id) => {
		setVisible(true);
		setActive(id);
	};

	const onDeleteHandler = () => {
		deleteVideoAction(active, closeModal, toast);
	};

	const closeModal = () => {
		setVisible(false);
	};

	useEffect(() => {
		getVideos();
	}, [getVideos]);
	return (
		<CompWrapper>
			<Header title="Videos" />
			<div className="videos">
				<div className="add-button">
					<Button
						className="button"
						variant="contained"
						style={{ marginBottom: 10 }}
						onClick={() => navigate(routes.AddVideos.path)}>
						Add Videos
					</Button>
				</div>
				<MaterialTable
					columns={[
						{
							title: "S.N.",
							field: "tableData.id",
							render: (rowData) => rowData.tableData.id + 1,
							width: 10,
						},
						{
							title: "Image",
							field: "image_path",
							width: 260,
							render: (rowData) => (
								<div
									className="image"
									style={{
										backgroundImage: `url(${
											rowData.video_link &&
											`http://img.youtube.com/vi/${rowData.video_link}/0.jpg`
										})`,
									}}></div>
							),
						},
						{ title: "Title", field: "title" },
						{
							title: "Type",
							field: "type",
							lookup: { interview: "Interview", lecture: "Lecture" },
							width: 150,
						},
						{
							title: "Date",
							field: "updated_at",
							width: 150,
							render: (rowData) =>
								rowData.updated_at && rowData.updated_at.slice(0, 10),
						},
					]}
					data={videoList}
					components={{
						Container: (props) => <Paper {...props} elevation={0} />,
					}}
					actions={[
						(rowData) => ({
							icon: "screen_share",
							tooltip: "Article Link",
							onClick: (event, rowData) => {
								window.open(
									"https://www.youtube.com/watch?v=" + rowData.video_link,
									"_blank",
								);
							},
						}),
						(rowData) => ({
							icon: "edit",
							tooltip: "Edit",
							onClick: (event, rowData) => {
								navigate(`${routes.Videos.path}/edit/${rowData.id}`);
							},
						}),
						(rowData) => ({
							icon: "delete",
							tooltip: "Delete",
							onClick: (event, rowData) => {
								deleteModal(rowData.id);
							},
						}),
					]}
					title=""
					options={{
						actionsColumnIndex: -1,
						emptyRowsWhenPaging: false,
						paging: true,
						search: true,
						headerStyle: {
							fontWeight: "bold",
							fontSize: 14,
							backgroundColor: "#0A5EBD",
							color: "#FFF",
						},
						rowStyle: (x) => {
							if (x.tableData.id % 2) {
								return { backgroundColor: "#f2f2f2", fontSize: 14 };
							} else {
								return { fontSize: 14 };
							}
						},
					}}
				/>
				<Modal visible={visible} onClose={() => setVisible(false)}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						Are you sure you want to delete?
						<ActivityIndicator animating={deleteVideoLoader}>
							<div
								style={{
									marginTop: 20,
									display: "flex",
									justifyContent: "flex-end",
								}}>
								<Button
									color="default"
									style={{ background: "red", color: "white" }}
									onClick={() => onDeleteHandler()}>
									Delete
								</Button>
								<Button color="secondary" onClick={() => setVisible(false)}>
									Cancel
								</Button>
							</div>
						</ActivityIndicator>
					</div>
				</Modal>
			</div>
		</CompWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		video: state.video,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getVideos,
			deleteVideoAction,
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);
