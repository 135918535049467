import React, { useState, useEffect } from "react";
import { useNavigation, Modal, useAuth } from "react-uicomp";
import MaterialTable from "material-table";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPosts, deletePostAction } from "../../actions/Actions";
import { BASE_URL_MEDIA } from "../../config/Config";

import CompWrapper from "../hocs/CompWrapper.hoc";
import Header from "../common/header/Header.common";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

const PostPage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { post, getPosts, deletePostAction } = props;
  const { postList, postLoader, deletePostLoader } = post;
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState();

  const deleteModal = (id) => {
    setVisible(true);
    setActive(id);
  };

  const onDeleteHandler = () => {
    deletePostAction(active, closeModal, toast);
  };

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  return (
    <CompWrapper>
      <Header title="Posts" />
      <div className="posts">
        <div className="add-button">
          <Button
            className="button"
            variant="contained"
            style={{ marginBottom: 10 }}
            onClick={() => navigate(routes.AddPosts.path)}>
            Create Posts
          </Button>
        </div>
        <MaterialTable
          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 10,
            },
            {
              title: "Image",
              field: "image_path",
              width: 260,
              render: (rowData) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${
                      rowData.image_path && BASE_URL_MEDIA + rowData.image_path
                    })`,
                  }}></div>
              ),
            },
            { title: "Title", field: "title" },
            { title: "Content", field: "content" },
            {
              title: "Date",
              field: "updated_at",
              width: 150,
              render: (rowData) =>
                rowData.updated_at && rowData.updated_at.slice(0, 10),
            },
          ]}
          data={postList}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                // navigate(routes.Posts.path + "/edit/" + rowData.id, {
                //   state: 7,
                //   color: "green",
                // });
                props.history.push(`/posts/edit/${rowData.id}`, {
                  content: rowData.content,
                });
              },
            }),
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteModal(rowData.id);
              },
            }),
          ]}
          title=""
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            paging: true,
            search: true,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 14,
              backgroundColor: "#0A5EBD",
              color: "#FFF",
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: "#f2f2f2", fontSize: 14 };
              } else {
                return { fontSize: 14 };
              }
            },
          }}
        />
        <Modal
          visible={visible}
          onOutsideClick={() => setVisible(false)}
          onClose={() => setVisible(false)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            Are you sure you want to delete?
            <ActivityIndicator animating={deletePostLoader}>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Button
                  color="default"
                  style={{ background: "red", color: "white" }}
                  onClick={() => onDeleteHandler()}>
                  Delete
                </Button>
                <Button color="secondary" onClick={() => setVisible(false)}>
                  Cancel
                </Button>
              </div>
            </ActivityIndicator>
          </div>
        </Modal>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    post: state.post,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPosts,
      deletePostAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostPage);
