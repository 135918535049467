import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editBooks, getBookDetailAction } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import DefaultButton from "../../common/button/Button.common";
import Card from "../../hocs/Card.hoc";

const EditBookPage = (props) => {
  const { book, editBooks, getBookDetailAction } = props;
  const { editBookLoader, getBookDetailLoader, bookDetail } = book;
  const { params, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control, errors } = useForm();
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState();
  const [showFileError, setShowFileError] = useState(false);
  const [chapterNumber, setChapterNumber] = useState(1);

  useEffect(() => {
    getBookDetailAction(params.id);
  }, [getBookDetailAction]);

  useEffect(() => {
    setChapterNumber(bookDetail ? bookDetail.table_of_contents.length : 1);
  }, [bookDetail]);

  const onSubmit = (data) => {
    let content = [];
    for (let index = 0; index < data.chapter.length; index++) {
      content.push({
        name: data.chapter[index],
        start: data.start[index],
      });
    }

    let formData = new FormData();

    if (image) {
      formData.append("image", image);
    }

    if (file && file[0]) {
      formData.append("pdf", file[0]);
    }

    formData.append("bookName", data.book_name);
    formData.append("genre", data.genre);
    formData.append("year", data.year);
    formData.append("description", data.description);
    formData.append("content", JSON.stringify(content));
    editBooks(params.id, formData, handleRedirection, toast);
  };

  const handleRedirection = () => {
    navigate(routes.Books.path);
  };

  const onChangeImage = (imageList) => {
    imageList[0]?.file && setImageError(false);
    setImage(imageList[0]?.file);
  };

  const onChangeFile = (event) => {
    setFile([event.target.files[0]]);
    setShowFileError(false);
  };

  return (
    <CompWrapper>
      <Header title="Edit Books" />
      <div className="addbooks-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{ cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate(routes.Books.path)}>
            Books
          </Link>
          <Typography color="textPrimary">Edit Books</Typography>
        </Breadcrumbs>
        <div className="addbooks">
          {bookDetail && !getBookDetailLoader ? (
            <Card>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  rowGap: 10,
                }}>
                <TextField
                  name="book_name"
                  inputRef={register({ required: true })}
                  variant="outlined"
                  label="Book Name*"
                  defaultValue={bookDetail.book_name}
                />
                <div className="input-container">
                  <TextField
                    name="year"
                    inputRef={register({ required: true })}
                    variant="outlined"
                    label="Year*"
                    type="number"
                    defaultValue={bookDetail.year}
                  />
                  <FormControl
                    variant="outlined"
                    control={control}
                    error={Boolean(errors.genre)}>
                    <InputLabel id="genre">Language</InputLabel>

                    <Controller
                      as={
                        <Select label="Genre">
                          <MenuItem value="">
                            <em>Select Language</em>
                          </MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Arabic">Arabic</MenuItem>
                          <MenuItem value="Turkish">Turkish</MenuItem>
                          <MenuItem value="Malay-Indonesian">
                            Malay-Indonesian
                          </MenuItem>
                          <MenuItem value="French">French</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      }
                      name="genre"
                      rules={{ required: "This is required" }}
                      control={control}
                      defaultValue={bookDetail.genre}
                    />
                  </FormControl>
                </div>

                <TextField
                  name="description"
                  multiline
                  rows={4}
                  inputRef={register({ required: true })}
                  variant="outlined"
                  label="Description*"
                  defaultValue={bookDetail.book_description}
                  inputProps={{ maxLength: 250 }}
                />

                <div className="button-container">
                  <Button
                    onClick={() => setChapterNumber(chapterNumber + 1)}
                    className="button-pri"
                    variant="contained">
                    Add Chapter
                  </Button>
                  {chapterNumber > 1 && (
                    <Button
                      onClick={() => setChapterNumber(chapterNumber - 1)}
                      className="button-sec"
                      color="secondary"
                      variant="contained">
                      Remove
                    </Button>
                  )}
                </div>
                {Array(chapterNumber)
                  .fill(10)
                  .map((_, index) => {
                    return (
                      <div key={index} className="input-container three">
                        <TextField
                          name={`chapter[${index}]`}
                          inputRef={register({ required: true })}
                          variant="outlined"
                          label="Chapter Name*"
                          defaultValue={
                            bookDetail?.table_of_contents[index]?.name
                          }
                        />
                        <TextField
                          name={`start[${index}]`}
                          inputRef={register({ required: true })}
                          variant="outlined"
                          label="Start Page*"
                          type="number"
                          defaultValue={
                            bookDetail?.table_of_contents[index]?.start
                          }
                        />
                      </div>
                    );
                  })}

                <ImageUploading multiple={false} onChange={onChangeImage}>
                  {({ imageList, onImageUpload }) => (
                    <div className="upload__image-wrapper">
                      <div
                        style={{
                          marginTop: 20,
                          display: "grid",
                          gridTemplateColumns:
                            "repeat( auto-fill, minmax(200px, 1fr) )",
                          columnGap: 10,
                        }}>
                        {imageList.map((image) => (
                          <div key={image.key}>
                            <img
                              src={image.dataURL}
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <Button
                        className="button-sec"
                        color="secondary"
                        onClick={onImageUpload}
                        variant="contained">
                        Change Image
                      </Button>
                    </div>
                  )}
                </ImageUploading>
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    overflow: "hidden",
                    padding: 10,
                    paddingLeft: 0,
                  }}>
                  <input
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      opacity: 0,
                      fontSize: 50,
                    }}
                    accept="application/pdf"
                    type="file"
                    multiple
                    name="thumbnail"
                    onChange={(event) => {
                      onChangeFile(event);
                    }}
                  />
                  <DefaultButton
                    onClick={() => false}
                    title="Change Pdf File"
                  />
                </div>
                {file && <div className="text-rumpel">{file[0]?.name}</div>}
                <ActivityIndicator animating={editBookLoader}>
                  <Button
                    className="button-pri"
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                </ActivityIndicator>
              </form>
            </Card>
          ) : (
            <div>loading...</div>
          )}
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    book: state.book,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editBooks,
      getBookDetailAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBookPage);
