import {
  GET_POST,
  ADD_POST,
  GET_POST_DETAIL,
  EDIT_POST,
  DELETE_POST,
} from "./Actions";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";

export function getPosts() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_POST.LOADING });
      res = await api(APIS.getpost);

      const { success, data } = res;

      if (success === true || success === "true") {
        dispatch({ type: GET_POST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_POST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_POST.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addPosts(formdata, handleRedirection, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_POST.LOADING });
      res = await api(`${APIS.posts}`, "POST", formdata, "formdata");

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: ADD_POST.SUCCESS });
        toast({ message: "Successfully Added Post", type: "success" });
        handleRedirection();
      } else {
        dispatch({ type: ADD_POST.ERROR });
        toast({ message: "Error Adding Post", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_POST.ERROR });
      toast({ message: "Error Adding Post", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function getPostDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_POST_DETAIL.LOADING });
      res = await api(`${APIS.posts}/${id}`);

      const { success, data } = res;
      console.log(res);
      if (success) {
        dispatch({ type: GET_POST_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_POST_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_POST_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editPosts(id, formdata, handleRedirection, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_POST.LOADING });
      res = await api(`${APIS.posts}/${id}`, "PATCH", formdata, "formdata");

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: EDIT_POST.SUCCESS });
        toast({ message: "Successfully Edited Post", type: "success" });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_POST.ERROR });
        toast({ message: "Error Editing Post", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_POST.ERROR });
      toast({ message: "Error Editing Post", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function deletePostAction(id, closeModal, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_POST.LOADING });
      res = await api(`${APIS.posts}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast({ message: "Successfully Deleted Post", type: "success" });
        dispatch({ type: DELETE_POST.SUCCESS });
        closeModal();
        dispatch(getPosts());
      } else {
        dispatch({ type: DELETE_POST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_POST.ERROR });
      console.error(message);
      return 0;
    }
  };
}
