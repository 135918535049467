import { LOGIN, SIGNUP, CHANGE_PASSWORD } from "./Actions";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";

export function loginAction(body, handleLogin, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: LOGIN.LOADING });
      res = await api(`${APIS.login}`, "POST", body);

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: LOGIN.SUCCESS });
        toast({ message: "Login Success!!", type: "success" });
        handleLogin(
          "admin",
          data.data.token,
          data.data.author_name,
          data.data.image_path,
        );
      } else {
        dispatch({ type: LOGIN.ERROR });
        toast({ message: "Login Failed!!", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: LOGIN.ERROR });
      toast({ message: "Login Failed!!", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function signupAction(formdata, handleRedirection, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: SIGNUP.LOADING });
      res = await api(`${APIS.signup}`, "POST", formdata, "formdata");

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: SIGNUP.SUCCESS });
        toast({ message: "Signup Success!!", type: "success" });
        handleRedirection();
      } else {
        dispatch({ type: SIGNUP.ERROR });
        toast({ message: "Signup Failed!!", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: SIGNUP.ERROR });
      toast({ message: "Signup Failed!!", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function changePasswordAction(body, modalCloseHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CHANGE_PASSWORD.LOADING });
      res = await api(`${APIS.changePass}`, "PATCH", body);

      const { success, data } = res;
      if (success === true || success === "true") {
        dispatch({ type: CHANGE_PASSWORD.SUCCESS });
        toast({ message: "Password Changed Successfully!!", type: "success" });
        modalCloseHandler();
      } else {
        dispatch({ type: CHANGE_PASSWORD.ERROR });
        toast({ message: "Change Password Failed!!", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: CHANGE_PASSWORD.ERROR });
      toast({ message: "Change Password Failed!!", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
