import {
  GET_BOOK,
  ADD_BOOK,
  DELETE_BOOK,
  GET_BOOK_DETAIL,
  EDIT_BOOK,
} from "../actions/Actions";

const initalState = {
  bookLoader: false,
  bookList: [],
  addBookLoader: false,
  getBookDetailLoader: false,
  bookDetail: null,
  editBookLoader: false,
  deleteBookLoader: false,
};

export function bookReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BOOK.LOADING:
      return { ...state, bookLoader: true, error: false };
    case GET_BOOK.SUCCESS:
      return {
        ...state,
        bookLoader: false,
        bookList: payload,
        error: false,
      };
    case GET_BOOK.ERROR:
      return { ...state, bookLoader: false, bookList: [], error: true };

    case ADD_BOOK.LOADING:
      return { ...state, addBookLoader: true, error: false };
    case ADD_BOOK.SUCCESS:
      return {
        ...state,
        addBookLoader: false,
        error: false,
      };
    case ADD_BOOK.ERROR:
      return { ...state, addBookLoader: false, error: true };

    case GET_BOOK_DETAIL.LOADING:
      return { ...state, getBookDetailLoader: true };
    case GET_BOOK_DETAIL.SUCCESS:
      return {
        ...state,
        getBookDetailLoader: false,
        bookDetail: payload,
      };
    case GET_BOOK_DETAIL.ERROR:
      return { ...state, getBookDetailLoader: false };

    case EDIT_BOOK.LOADING:
      return { ...state, editBookLoader: true, error: false };
    case EDIT_BOOK.SUCCESS:
      return {
        ...state,
        editBookLoader: false,
        error: false,
      };
    case EDIT_BOOK.ERROR:
      return { ...state, editBookLoader: false, error: true };

    case DELETE_BOOK.LOADING:
      return { ...state, deleteBookLoader: true, error: false };
    case DELETE_BOOK.SUCCESS:
      return {
        ...state,
        deleteBookLoader: false,
        error: false,
      };
    case DELETE_BOOK.ERROR:
      return { ...state, deleteBookLoader: false, error: true };
    default:
      return state;
  }
}
