import React, { useState, useEffect } from "react";
import { useNavigation, Modal, useAuth } from "react-uicomp";
import MaterialTable from "material-table";
import { Button, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getArticles, deleteArticleAction } from "../../actions/Actions";
import { BASE_URL_MEDIA } from "../../config/Config";

import CompWrapper from "../hocs/CompWrapper.hoc";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";
import Header from "../common/header/Header.common";

const ArticlePage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { article, getArticles, deleteArticleAction } = props;
  const { articleList, articleLoader, deleteArticleLoader } = article;
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState();

  useEffect(() => {
    getArticles();
  }, [getArticles]);

  const deleteModal = (id) => {
    setVisible(true);
    setActive(id);
  };

  const onDeleteHandler = () => {
    deleteArticleAction(active, closeModal, toast);
  };

  const closeModal = () => {
    setVisible(false);
  };
  return (
    <CompWrapper>
      <Header title="Articles" />
      <div className="articles">
        <div className="add-button">
          <Button
            className="button"
            variant="contained"
            style={{ marginBottom: 10 }}
            onClick={() => navigate(routes.AddArticles.path)}>
            Add Articles
          </Button>
        </div>
        <MaterialTable
          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 10,
            },
            {
              title: "Image",
              field: "image_path",
              width: 260,
              render: (rowData) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${
                      rowData.image_path && BASE_URL_MEDIA + rowData.image_path
                    })`,
                  }}></div>
              ),
            },
            { title: "Title", field: "title" },
            {
              title: "Date",
              field: "updated_at",
              width: 150,
              render: (rowData) =>
                rowData.updated_at && rowData.updated_at.slice(0, 10),
            },
            { title: "Views", field: "views" },
            { title: "Downloads", field: "downloads" },
          ]}
          data={articleList}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "screen_share",
              tooltip: "Article Link",
              hidden: rowData.article_link ? false : true,
              onClick: (event, rowData) => {
                window.open(rowData.article_link);
              },
            }),
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                navigate(`${routes.Articles.path}/edit/${rowData.id}`);
              },
            }),
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteModal(rowData.id);
              },
            }),
          ]}
          title=""
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            paging: true,
            search: true,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 14,
              backgroundColor: "#0A5EBD",
              color: "#FFF",
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: "#f2f2f2", fontSize: 14 };
              } else {
                return { fontSize: 14 };
              }
            },
          }}
        />
        <Modal visible={visible} onClose={() => setVisible(false)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            Are you sure you want to delete?
            <ActivityIndicator animating={deleteArticleLoader}>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <Button
                  color="default"
                  style={{ background: "red", color: "white" }}
                  onClick={() => onDeleteHandler()}>
                  Delete
                </Button>
                <Button color="secondary" onClick={() => setVisible(false)}>
                  Cancel
                </Button>
              </div>
            </ActivityIndicator>
          </div>
        </Modal>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    article: state.article,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getArticles,
      deleteArticleAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage);
