import {
  GET_BOOK,
  ADD_BOOK,
  GET_BOOK_DETAIL,
  EDIT_BOOK,
  DELETE_BOOK,
} from "./Actions";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";

export function getBooks() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_BOOK.LOADING });
      res = await api(APIS.getBook);

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: GET_BOOK.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_BOOK.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_BOOK.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addBooks(formdata, handleRedirection, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_BOOK.LOADING });
      res = await api(`${APIS.books}`, "POST", formdata, "formdata");

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: ADD_BOOK.SUCCESS });
        toast({ message: "Successfully Added Book", type: "success" });
        handleRedirection();
      } else {
        dispatch({ type: ADD_BOOK.ERROR });
        toast({ message: "Error Adding Book", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_BOOK.ERROR });
      toast({ message: "Error Adding Book", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function getBookDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_BOOK_DETAIL.LOADING });
      res = await api(`${APIS.books}/${id}`);

      const { success, data } = res;
      console.log(res);
      if (success) {
        dispatch({ type: GET_BOOK_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_BOOK_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_BOOK_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editBooks(id, formdata, handleRedirection, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_BOOK.LOADING });
      res = await api(`${APIS.books}/${id}`, "PATCH", formdata, "formdata");

      const { success, data } = res;
      console.log(res);
      if (success === true || success === "true") {
        dispatch({ type: EDIT_BOOK.SUCCESS });
        toast({ message: "Successfully Edited Book", type: "success" });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_BOOK.ERROR });
        toast({ message: "Error Editing Book", type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_BOOK.ERROR });
      toast({ message: "Error Editing Book", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

export function deleteBookAction(id, closeModal, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_BOOK.LOADING });
      res = await api(`${APIS.books}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast({ message: "Successfully Deleted Book", type: "success" });
        dispatch({ type: DELETE_BOOK.SUCCESS });
        closeModal();
        dispatch(getBooks());
      } else {
        dispatch({ type: DELETE_BOOK.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_BOOK.ERROR });
      console.error(message);
      return 0;
    }
  };
}
