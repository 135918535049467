import {
	GET_VIDEO,
	ADD_VIDEO,
	GET_VIDEO_DETAIL,
	EDIT_VIDEO,
	DELETE_VIDEO,
} from "./Actions";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";

export function getVideos() {
	return async function (dispatch) {
		let res;
		try {
			dispatch({ type: GET_VIDEO.LOADING });
			res = await api(APIS.getvideo);

			const { success, data } = res;
			console.log(res);
			if (success === true || success === "true") {
				dispatch({ type: GET_VIDEO.SUCCESS, payload: data.data });
			} else {
				dispatch({ type: GET_VIDEO.ERROR });
			}
		} catch ({ message }) {
			dispatch({ type: GET_VIDEO.ERROR });
			console.error(message);
			return 0;
		}
	};
}

export function addVideos(body, handleRedirection, toast) {
	return async function (dispatch) {
		let res;
		try {
			dispatch({ type: ADD_VIDEO.LOADING });
			res = await api(`${APIS.videos}`, "POST", body);

			const { success, data } = res;
			if (success === true || success === "true") {
				dispatch({ type: ADD_VIDEO.SUCCESS });
				toast({ message: "Successfully Added Video", type: "success" });
				handleRedirection();
			} else {
				dispatch({ type: ADD_VIDEO.ERROR });
				toast({ message: "Error Adding Video", type: "error" });
			}
		} catch ({ message }) {
			dispatch({ type: ADD_VIDEO.ERROR });
			toast({ message: "Error Adding Video", type: "error" });
			console.error(message);
			return 0;
		}
	};
}

export function getVideoDetailAction(id) {
	return async function (dispatch) {
		let res;
		try {
			dispatch({ type: GET_VIDEO_DETAIL.LOADING });
			res = await api(`${APIS.videos}/${id}`);

			const { success, data } = res;
			console.log(res);
			if (success) {
				dispatch({ type: GET_VIDEO_DETAIL.SUCCESS, payload: data.data[0] });
			} else {
				dispatch({ type: GET_VIDEO_DETAIL.ERROR });
			}
		} catch ({ message }) {
			dispatch({ type: GET_VIDEO_DETAIL.ERROR });
			console.error(message);
			return 0;
		}
	};
}

export function editVideos(id, body, handleRedirection, toast) {
	return async function (dispatch) {
		let res;
		try {
			dispatch({ type: EDIT_VIDEO.LOADING });
			res = await api(`${APIS.videos}/${id}`, "PATCH", body);

			const { success, data } = res;
			console.log(res);
			if (success === true || success === "true") {
				dispatch({ type: EDIT_VIDEO.SUCCESS });
				toast({ message: "Successfully Edited Video", type: "success" });
				handleRedirection();
			} else {
				dispatch({ type: EDIT_VIDEO.ERROR });
				toast({ message: "Error Editing Video", type: "error" });
			}
		} catch ({ message }) {
			dispatch({ type: EDIT_VIDEO.ERROR });
			toast({ message: "Error Editing Video", type: "error" });
			console.error(message);
			return 0;
		}
	};
}

export function deleteVideoAction(id, closeModal, toast) {
	return async function (dispatch) {
		let res;
		try {
			dispatch({ type: DELETE_VIDEO.LOADING });
			res = await api(`${APIS.videos}/${id}`, "DELETE");
			const { success, data } = res;
			if (success === "true") {
				toast({ message: "Successfully Deleted Video", type: "success" });
				dispatch({ type: DELETE_VIDEO.SUCCESS });
				closeModal();
				dispatch(getVideos());
			} else {
				dispatch({ type: DELETE_VIDEO.ERROR });
			}
		} catch ({ message }) {
			dispatch({ type: DELETE_VIDEO.ERROR });
			console.error(message);
			return 0;
		}
	};
}
