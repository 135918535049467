import { combineReducers } from "redux";
import { sampleReducer } from "./Sample.reducer";
import { bookReducer } from "./Book.reducer";
import { articleReducer } from "./Article.reducer";
import { postReducer } from "./Post.reducer";
import { videoReducer } from "./Video.reducer";
import { loginReducer } from "./Login.reducer";

const rootReducer = combineReducers({
	samples: sampleReducer,
	book: bookReducer,
	article: articleReducer,
	post: postReducer,
	video: videoReducer,
	login: loginReducer,
});

export default rootReducer;
