import React, { useState } from "react";
import { Auth, Navigation, Toast, useToast } from "react-uicomp";
import Header from "../common/header/Header.common";
import SideNavigation from "../common/sideNavigation/SideNavigation.common";
import { PUBLICPATHS, PRIVATEPATHS } from "./routes";
import { USER_ROLES } from "./userRoles";
// return `Auth.Provider` with `Auth.Screens` inside it
const App = () => {
  const { handler, toast } = useToast();
  const isLoggedIn = localStorage.getItem("isLoggedin") ? true : false;
  const userRole = localStorage.getItem("userRole")
    ? localStorage.getItem("userRole")
    : "user";
  const token = localStorage.getItem("token");
  const authorName = localStorage.getItem("authorName");
  const imagePath = localStorage.getItem("imagePath");

  const [sideNavVisible, setSideNavVisible] = useState(false);
  const [fullPageLoaderVisible, setFullpageLoaderVisible] = useState(false);
  const [config, setConfig] = useState({
    isLoggedIn: isLoggedIn,
    userRole: userRole,
    token_id: token,
    authorName: authorName,
    imagePath: imagePath,
  });

  return (
    <Navigation.Provider
      publicPaths={PUBLICPATHS}
      privatePaths={PRIVATEPATHS}
      userRoles={USER_ROLES}
      routerType="hash">
      <Auth.Provider
        config={config}
        state={{
          toast,
          setSideNavVisible,
          setFullpageLoader: (val) => setFullpageLoaderVisible(val),
          handleLogin: (userRole, token, authorName, imagePath) => {
            localStorage.setItem("isLoggedin", true);
            localStorage.setItem("userRole", userRole);
            localStorage.setItem("token", token);
            localStorage.setItem("authorName", authorName);
            localStorage.setItem("imagePath", imagePath);
            setConfig({
              isLoggedIn: true,
              userRole: userRole,
              token_id: token,
              authorName: authorName,
              imagePath: imagePath,
            });
          },
          handleLogout: () => {
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("userRole");
            localStorage.removeItem("token");
            localStorage.removeItem("authorName");
            localStorage.removeItem("imagePath");
            setConfig({
              isLoggedIn: false,
              userRole: "user",
              token_id: "",
              authorName: "",
              imagePath: "",
            });
          },
        }}>
        <Auth.Screens />
        <SideNavigation
          mobileSideNavVisible={sideNavVisible}
          setMobileSideNavVisible={setSideNavVisible}
        />
        <Toast {...handler} />
      </Auth.Provider>
    </Navigation.Provider>
  );
};

export default App;
