import { createActionSet } from "../helpers/Helpers";

// SAMPLE ACTION SET
/* 
  OBJECT STRUCTURE 
  { 
    LOADING: "SAMPLE_LOADING",
    SUCCESS: "SAMPLE_SUCCESS",  
    ERROR: "SAMPLE_ERROR",
    SAMPLE: "SAMPLE"  
  } 
*/
export const SAMPLE = createActionSet("SAMPLE");

//ARTICLE ACTION SET
export const GET_ARTICLE = createActionSet("GET_ARTICLE");
export const ADD_ARTICLE = createActionSet("ADD_ARTICLE");
export const GET_ARTICLE_DETAIL = createActionSet("GET_ARTICLE_DETAIL");
export const EDIT_ARTICLE = createActionSet("EDIT_ARTICLE");
export const DELETE_ARTICLE = createActionSet("DELETE_ARTICLE");

//POST ACTION SET
export const GET_POST = createActionSet("GET_POST");
export const ADD_POST = createActionSet("ADD_POST");
export const GET_POST_DETAIL = createActionSet("GET_POST_DETAIL");
export const EDIT_POST = createActionSet("EDIT_POST");
export const DELETE_POST = createActionSet("DELETE_POST");

//BOOK ACTION SET
export const GET_BOOK = createActionSet("GET_BOOK");
export const ADD_BOOK = createActionSet("ADD_BOOK");
export const GET_BOOK_DETAIL = createActionSet("GET_BOOK_DETAIL");
export const EDIT_BOOK = createActionSet("EDIT_BOOK");
export const DELETE_BOOK = createActionSet("DELETE_BOOK");

//VIDEO ACTION SET
export const GET_VIDEO = createActionSet("GET_VIDEO");
export const ADD_VIDEO = createActionSet("ADD_VIDEO");
export const GET_VIDEO_DETAIL = createActionSet("GET_VIDEO_DETAIL");
export const EDIT_VIDEO = createActionSet("EDIT_VIDEO");
export const DELETE_VIDEO = createActionSet("DELETE_VIDEO");

//LOGIN ACTION SET
export const LOGIN = createActionSet("LOGIN");
export const SIGNUP = createActionSet("SIGNUP");

//CHANGE PASSWORD ACTION SET
export const CHANGE_PASSWORD = createActionSet("CHANGE_PASSWORD");
