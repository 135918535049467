export const APIS = {
  sample: "/sample",
  getBook: "/book/mybook",
  books: "/book",
  getArticle: "/articles/myarticles",
  articles: "/articles",
  getpost: "/post/mypost",
  posts: "/post",
  getvideo: "/video/myvideo",
  videos: "/video",
  login: "/auth/login",
  signup: "/auth/addauthor",
  changePass: "/auth/password",
};
