import React from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { NavLink } from "react-router-dom";
import {
  MdDashboard,
  FaBookOpen,
  BsFilePost,
  IoMdListBox,
  MdOndemandVideo,
  MdClose,
} from "react-icons/all";

import Logo from "../../../assets/logo.jpg";
import { CSSTransition } from "react-transition-group";

const SideNav = () => {
  const { navigation } = useNavigation();
  const { setSideNavVisible } = useAuth();
  const { routes } = navigation;

  return (
    <div className="sidenavigation-container">
      <div className="sidenavigation">
        <div className="sidenavigation-header">
          <img src={Logo} alt="Now Test Logo" />
        </div>

        <ul
          className="sidenavigation-list"
          onClick={() => setSideNavVisible(false)}>
          {routes.Books && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Books.path} activeClassName="active">
                <span className="icon">
                  <FaBookOpen />
                </span>
                <span className="title">{routes.Books.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Posts && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Posts.path} activeClassName="active">
                <span className="icon">
                  <BsFilePost />
                </span>
                <span className="title">{routes.Posts.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Articles && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Articles.path} activeClassName="active">
                <span className="icon">
                  <IoMdListBox />
                </span>
                <span className="title">{routes.Articles.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Videos && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Videos.path} activeClassName="active">
                <span className="icon">
                  <MdOndemandVideo />
                </span>
                <span className="title">{routes.Videos.name}</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default function SideNavigation(props) {
  const { mobileSideNavVisible, setMobileSideNavVisible } = props;
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return (
      <>
        <CSSTransition
          in={mobileSideNavVisible}
          timeout={200}
          classNames="sidenavigation-node"
          unmountOnExit>
          <div className="sidenavigation-mobile-container">
            <div className="sidenavigation-mobile">
              <SideNav />

              <div
                className="close-sidenav"
                onClick={() => setMobileSideNavVisible(false)}>
                <MdClose />
              </div>
            </div>
          </div>
        </CSSTransition>

        <div className="sidenavigation-web">
          <SideNav />
        </div>
      </>
    );
  } else {
    return null;
  }
}
