import React, { useState } from "react";
import { Dropdown, Modal, useAuth } from "react-uicomp";
import { MdMenu, BsCaretDownFill } from "react-icons/all";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changePasswordAction } from "../../../actions/Actions";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../dropdown/Dropdown.common";
import { BASE_URL_MEDIA } from "../../../config/Config";

const Header = (props) => {
  const {
    setSideNavVisible,
    handleLogout,
    userRole,
    authorName,
    imagePath,
    toast,
  } = useAuth();
  const { handleSubmit, register, setValue, control } = useForm();
  const [visible, setVisible] = useState(false);
  const { changePasswordAction, login, title } = props;
  const { changePasswordLoader } = login;

  const onSubmit = (data) => {
    let body = {
      oldPassword: data.old_password,
      newPassword: data.new_password,
    };
    if (data.new_password === data.confirm_password) {
      changePasswordAction(body, modalCloseHandler, toast);
    } else {
      toast({
        message: "Password doesn't match. Please recheck",
        type: "error",
      });
    }
  };

  const modalCloseHandler = () => {
    setVisible(false);
  };
  return (
    <div className="header-container">
      <div className="header">
        <div className="header-menu" onClick={() => setSideNavVisible(true)}>
          <MdMenu />
        </div>
        <div className="header-left">{title}</div>
        <div className="header-right">
          <Dropdown
            placement="bottomright"
            triggerElement={({ active }) => (
              <div
                className={
                  active
                    ? "header-right-profile active"
                    : "header-right-profile"
                }>
                <div
                  style={{
                    backgroundImage: `url(${BASE_URL_MEDIA + imagePath})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  className="profile-image"
                />
                <div className="profile-name">
                  <span>{authorName}</span>
                  <BsCaretDownFill size={14} />
                </div>
              </div>
            )}>
            <DropdownMenu>
              <DropdownMenuItem onClick={() => setVisible(true)}>
                Change Password
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem danger onClick={handleLogout}>
                Logout
              </DropdownMenuItem>
            </DropdownMenu>
          </Dropdown>
          <Modal visible={visible} onOutsideClick={() => setVisible(false)}>
            <h3 style={{ marginBottom: 20 }}>Change Password</h3>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: 10,
              }}>
              <TextField
                name="old_password"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Old Password"
                type="password"
              />
              <TextField
                name="new_password"
                inputRef={register({ required: true })}
                variant="outlined"
                label="New Password"
                type="password"
              />
              <TextField
                name="confirm_password"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Confirm Password"
                type="password"
              />
              <ActivityIndicator animating={changePasswordLoader}>
                <Button
                  className="button-pri"
                  variant="contained"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changePasswordAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
