import {
  GET_VIDEO,
  ADD_VIDEO,
  GET_VIDEO_DETAIL,
  EDIT_VIDEO,
  DELETE_VIDEO,
} from "../actions/Actions";

const initalState = {
  videoLoader: false,
  videoList: [],
  addVideoLoader: false,
  getVideoDetailLoader: false,
  videoDetail: null,
  editVideoLoader: false,
  deleteVideoLoader: false,
};

export function videoReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VIDEO.LOADING:
      return { ...state, videoLoader: true, error: false };
    case GET_VIDEO.SUCCESS:
      return {
        ...state,
        videoLoader: false,
        videoList: payload,
        error: false,
      };
    case GET_VIDEO.ERROR:
      return { ...state, videoLoader: false, videoList: [], error: true };

    case ADD_VIDEO.LOADING:
      return { ...state, addVideoLoader: true, error: false };
    case ADD_VIDEO.SUCCESS:
      return {
        ...state,
        addVideoLoader: false,
        error: false,
      };
    case ADD_VIDEO.ERROR:
      return { ...state, addVideoLoader: false, error: true };

    case GET_VIDEO_DETAIL.LOADING:
      return { ...state, getVideoDetailLoader: true };
    case GET_VIDEO_DETAIL.SUCCESS:
      return {
        ...state,
        getVideoDetailLoader: false,
        videoDetail: payload,
      };
    case GET_VIDEO_DETAIL.ERROR:
      return { ...state, getVideoDetailLoader: false };

    case EDIT_VIDEO.LOADING:
      return { ...state, editVideoLoader: true, error: false };
    case EDIT_VIDEO.SUCCESS:
      return {
        ...state,
        editVideoLoader: false,
        error: false,
      };
    case EDIT_VIDEO.ERROR:
      return { ...state, editVideoLoader: false, error: true };

    case DELETE_VIDEO.LOADING:
      return { ...state, deleteVideoLoader: true, error: false };
    case DELETE_VIDEO.SUCCESS:
      return {
        ...state,
        deleteVideoLoader: false,
        error: false,
      };
    case DELETE_VIDEO.ERROR:
      return { ...state, deleteVideoLoader: false, error: true };
    default:
      return state;
  }
}
