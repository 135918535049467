// CREATE ROLES FOR USER

// SET ACCESS ROUTES FOR ALL ROLES
export const USER_ROLES = {
  admin: {
    access: [
      "/",
      "/log-in",
      "/dashboard",
      "/books",
      "/books/add",
      "/books/edit/:id",
      "/posts",
      "/posts/add",
      "/posts/edit/:id",
      "/articles",
      "/articles/add",
      "/articles/edit/:id",
      "/videos",
      "/videos/add",
      "/videos/edit/:id",
      "/privacy-policy",
    ],
  },
  user: {
    access: ["/", "/log-in", "/privacy-policy"],
  },
};
