import { LOGIN, SIGNUP, CHANGE_PASSWORD } from "../actions/Actions";

const initalState = {
  loginLoader: false,
  signupLoader: false,
  changePasswordLoader: false,
};

export function loginReducer(state = initalState, action) {
  const { type } = action;

  switch (type) {
    case LOGIN.LOADING:
      return { ...state, loginLoader: true, error: false };
    case LOGIN.SUCCESS:
      return { ...state, loginLoader: false, error: false };
    case LOGIN.ERROR:
      return { ...state, loginLoader: false, error: true };

    case SIGNUP.LOADING:
      return { ...state, signupLoader: true, error: false };
    case SIGNUP.SUCCESS:
      return { ...state, signupLoader: false, error: false };
    case SIGNUP.ERROR:
      return { ...state, signupLoader: false, error: true };

    case CHANGE_PASSWORD.LOADING:
      return { ...state, changePasswordLoader: true, error: false };
    case CHANGE_PASSWORD.SUCCESS:
      return { ...state, changePasswordLoader: false, error: false };
    case CHANGE_PASSWORD.ERROR:
      return { ...state, changePasswordLoader: false, error: true };
    default:
      return state;
  }
}
